import React from 'react'
import PropTypes from 'prop-types'
import styles from './dancing-video.module.scss'
import debounce from 'lodash.debounce'

class DancingVideo extends React.Component {
  constructor(props) {
    super(props)
    this.animationFrame = null
    this.video = React.createRef()
    this.canplayHandler = this.canplayHandler.bind(this)
    this.animationLoop = this.animationLoop.bind(this)
    this.calcDimensions = this.calcDimensions.bind(this)
    this.debouncedCalcDimensions = debounce(this.calcDimensions, 300)

    this.shouldBePlaying = false
    this.isSafeToPause = false
    this.state = {
      translate: 0,
    }
    this.lastTranslate = 0
    this.lastPlayPosition = 0
    this.maxDiff = 0
    this.displayWidth = 0.5
  }

  componentDidMount() {
    this.animationFrame = requestAnimationFrame(this.animationLoop)
    this.video.current.addEventListener('canplay', this.canplayHandler)
    window.addEventListener('resize', this.debouncedCalcDimensions)
    this.calcDimensions()
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrame)
    this.video.current.removeEventListener('canplay', this.canplayHandler)
    this.shouldBePlaying = false
    this.isSafeToPause = false
    window.removeEventListener('resize', this.debouncedCalcDimensions)
  }

  play() {
    if (!this.video) {
      return
    }
    this.shouldBePlaying = true
    let playPromise = this.video.current.play()
    if (playPromise) {
      this.isSafeToPause = false
      playPromise
        .then(() => {
          if (this.shouldBePlaying) {
            this.isSafeToPause = true
          } else {
            this.video && this.video.current.pause()
          }
        })
        .catch(() => {
          // TODO maybe offer a new way to play or change a play
          // button's state (in another component even)
        })
    } else {
      this.isSafeToPause = true
    }
  }

  pause() {
    if (!this.video) {
      return
    }
    this.shouldBePlaying = false
    if (this.isSafeToPause) {
      this.video.current.pause()
    }
  }

  canplayHandler() {
    this.calcDimensions()
  }

  animationLoop() {
    this.animationFrame = requestAnimationFrame(this.animationLoop)
    let playPosition =
      this.video.current.currentTime / this.video.current.duration
    let translate = this.calcTranslate(playPosition)
    this.setState({ translate })
    if (this.props.onPlayPositionChange)
      this.props.onPlayPositionChange(playPosition)
  }

  getPlayPosition() {
    return (
      this.video && this.video.current.currentTime / this.video.current.duration
    )
  }

  setCurrentTime(seconds) {
    if (!this.video) {
      return
    }
    this.video.current.currentTime = seconds
  }

  isMuted() {
    return !!(this.video && this.video.current.muted)
  }

  calcDimensions() {
    this.maxDiff = this.props.containerHeight * (this.props.maxDiff || 1)
    this.displayWidth = window.innerWidth > 768 ? 0.5 : 0.8
  }

  calcTranslate(playPosition) {
    if (playPosition === this.lastPlayPosition) {
      return this.lastTranslate
    }
    this.lastPlayPosition = playPosition
    let sampleIndex = Math.floor(playPosition * this.props.waveform.data.length)
    if (sampleIndex >= this.props.waveform.data.length) {
      sampleIndex = this.props.waveform.data.length - 1
    }
    let absSampleVal = Math.abs(this.props.waveform.data[sampleIndex])
    let normSampleVal = absSampleVal / Math.pow(2, this.props.waveform.bits - 1)
    let translate = normSampleVal * (this.props.containerHeight / 2)
    let diff = translate - this.lastTranslate
    let absDiff = Math.abs(diff)
    let diffSign = diff / absDiff
    if (absDiff > this.maxDiff) {
      translate = this.lastTranslate + this.maxDiff * diffSign
    }
    this.lastTranslate = translate
    return translate
  }

  render() {
    let width, height, translateX
    if (this.props.fullscreen) {
      let containerAspect =
        this.props.containerWidth / this.props.containerHeight
      if (containerAspect < this.props.aspectRatio) {
        // container is narrower
        width = this.props.containerWidth
        height = width / this.props.aspectRatio
      } else {
        // container is wider
        height = this.props.containerHeight
        width = height * this.props.aspectRatio
      }
      translateX = '0'
    } else {
      width = this.props.containerWidth * this.displayWidth
      height = width / this.props.aspectRatio
      translateX = `-${this.state.translate}px`
    }
    let left = Math.floor(this.props.containerWidth / 2 - width / 2)
    let top = Math.floor(this.props.containerHeight / 2 - height / 2)
    let transform = `translate3d(0, ${translateX}, 0)`
    return (
      <video
        ref={this.video}
        src={this.props.src}
        muted={this.props.muted !== void 0 ? this.props.muted : false}
        loop={this.props.loop !== void 0 ? this.props.loop : false}
        autoPlay={this.props.autoPlay !== void 0 ? this.props.autoPlay : false}
        playsInline
        className={styles.video}
        style={{
          left,
          top,
          width,
          height,
          transform,
          transition: 'opacity 0.25s ease',
          opacity: this.props.blind ? 0 : 1,
          cursor: 'pointer',
        }}
        onClick={this.props.onClick}
      />
    )
  }
}

DancingVideo.propTypes = {
  src: PropTypes.string.isRequired,
  waveform: PropTypes.object.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  maxDiff: PropTypes.number,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  fullscreen: PropTypes.bool,
  blind: PropTypes.bool,
  onPlayPositionChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
}

export default DancingVideo
